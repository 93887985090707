











import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import HeaderSection from '@/components/HeaderSection.vue'
import { fetchAllGenres } from '@/api/genre'

@Component({ components: { HeaderSection } })
export default class GenresPage extends Vue {
    genres: IGenre[] = []

    async mounted() {
        this.genres = await fetchAllGenres()
    }
}
