var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view" },
    [
      _c("header-section", {
        attrs: { heading: "Genres", subheading: "Filter our beats by genre." },
      }),
      _c("section", { staticClass: "section" }, [
        _c(
          "div",
          {
            staticClass:
              "container tileGenresInstrumentsPlaylists__gridContainer",
          },
          _vm._l(_vm.genres, function (genre) {
            return _c(
              "router-link",
              {
                key: genre.id,
                staticClass: "tileGenresInstrumentsPlaylists",
                attrs: { to: { name: "Genre", params: { slug: genre.slug } } },
              },
              [
                _c("div", [
                  _c("img", {
                    staticClass: "tileGenresInstrumentsPlaylists__icon",
                    attrs: { src: genre.image_thumbnail_url },
                  }),
                  _c(
                    "div",
                    { staticClass: "tileGenresInstrumentsPlaylists__name" },
                    [_vm._v(_vm._s(genre.name))]
                  ),
                ]),
              ]
            )
          }),
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }